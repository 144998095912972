import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DatePicker from "react-datepicker";


const MainDates = ({item, handleDate, disabled=false}) => {

    // console.log(item[1])

    // dayjs.extend(customParseFormat);
    // const newDate = dayjs(item[1], "DD-MM-YY").isValid() ? dayjs(item[1]).toDate() : null

    // console.log(newDate)

    return (
        <div className='span-7 select-date'>
            <DatePicker
                className="input-date"
                dateFormat="dd / MM / yyyy"
                selected={ item[1] instanceof Date && !isNaN(item[1].getTime()) ? item[1] : null}
                onChange={ (date) => handleDate( item[0], date ) }
                maxDate={ new Date() }
                placeholderText="Select a date"
                disabled={disabled}
            />
        </div>
    )
}

export default MainDates