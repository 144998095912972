import React, { useState, useRef, useEffect } from 'react';
import { sleep } from '../../../helpers/sleep';
import { IoCodeOutline, IoSearchOutline } from 'react-icons/io5';
import { ComboboxOption } from '../../../interfaces/interfaces';
import { InputCustom } from './InputCustom';


interface Props {
    options: ComboboxOption[];
    value: ComboboxOption | null;
    onChange: (option: ComboboxOption) => void;
    defaultValue?: string
}

const Combobox = ({ options, value, onChange, defaultValue = "Select" }: Props) => {

    const [openSelect, setOpenSelect] = useState<boolean>(false)
    const [inputSelect, setInputSelect] = useState<string>("")
    const [result, setResult] = useState<ComboboxOption[]>([])

    const [label, setLabel] = useState<string>(defaultValue)

    const inputRef = useRef<HTMLInputElement>(null)
    const optionRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setLabel(options.find(option => option.value === value?.value)?.label || defaultValue)
    }, [value])


    useEffect(() => {
        if (inputSelect.length > 0) {
            const searching = options.filter(option => (option.label).toLowerCase().includes(inputSelect.toLowerCase()))
            setResult(searching)
        } else {
            setResult(options)
        }
    }, [inputSelect, options])

    const handleClickOutside = async(e: any) => {
        if (optionRef.current && !optionRef.current.contains(e.target)) {
            setOpenSelect(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='combobox' ref={optionRef}>
            <div
                className='combobox-selector w-100 flex-space-between border mb-1'
                onClick={async () => {
                    setOpenSelect(!openSelect)
                    await sleep(.01)
                    inputRef.current?.focus()

                }}>{label}
                <IoCodeOutline style={{ rotate: "90deg" }} />

            </div>

            {
                openSelect &&
                <div className='combobox-wrapper'>

                    <label htmlFor="custom-input" className="icon-input flex">
                        <IoSearchOutline />
                        <input
                            ref={inputRef}
                            className='w-100 ml-05'
                            type="text"
                            value={inputSelect}
                            onChange={(e) => setInputSelect(e.target.value)}
                        />
                    </label>

                    <div className={`combobox-options py-05 mt-02`}>
                        {
                            result.map(option => (
                                <div key={option.value} className='combobox-option px-1 cursor'
                                    onClick={() => {
                                        onChange(option)
                                        setInputSelect("")
                                        setOpenSelect(false)
                                    }} >
                                    <p className='py-02'>{option.label}</p>
                                </div>
                            ))
                        }
                        {/* {
                            result.map(option => (
                               <div key={option.value} className='combobox-option px-1'>
                                 <button
                                    className='py-02'
                                    onClick={() => {
                                        onChange(option)
                                        setInputSelect("")
                                        setOpenSelect(false)
                                    }}
                                >
                                    {option.label}
                                </button>
                               </div>
                            ))
                        } */}
                    </div>
                </div>
            }

        </div>
    );
};

export default Combobox;
