import { useState } from 'react'
import { ModalBtn } from './ModalBtn'
import { useCreateContact, useCreateSupplier, useEditContact, useRemoveContact } from '../../api/useSupplier'
import { Supplier, SupplierContact, SupplierList } from '../../interfaces/interfaces.auth'
import { useDispatch } from 'react-redux'
import { startChecking } from '../../store/asyncMethods/authMethods'
import { hasLength } from '../../helpers/validators'
import toast from 'react-hot-toast'
import { isEmail } from '../../helpers/isEmail'
import { IoTrashOutline } from 'react-icons/io5'
import { ModalConfirmation } from '../ui/ModalConfirmation'

interface Props {
    target: SupplierList
    supplier: Supplier
    contact: SupplierContact
    closeModal: () => void
}

export const ModalEditSupplierContact = ({ closeModal, supplier, target, contact }: Props) => {

    const dispatch = useDispatch()

    const [newName, setNewName] = useState<string>(contact.name)
    const [newEmail, setNewEmail] = useState<string>(contact.email)
    const [confirmation, setConfirmation] = useState<boolean>(false)

    const contacts = supplier.contacts

    const { mutate, isLoading } = useEditContact()
    const { mutate:mutateContact, isLoading:isRemoving } = useRemoveContact()

    const editContact = () => {

        const cleanName = newName.trim()
        const cleanEmail = newEmail.trim()

        if (cleanEmail === contact.email && cleanName === contact.name) return closeModal()
        if (contacts.filter(sup => sup._id !== contact._id).find(sup => sup.email === cleanEmail)) return toast.error("Contact already exists")

        if (!hasLength(newEmail) || !hasLength(newName)) return toast.error("Please fill in all fields")
        if (newName.length < 3) return toast.error("Name must be at least 3 characters")
        if (!isEmail(newEmail)) return toast.error('Please enter a valid Email')

        mutate({
            id: target.id,
            model: target.model,
            supplierId: supplier._id,
            contactId: contact._id,
            name: cleanName,
            email: cleanEmail,
        }, {
            onSuccess: async () => {
                dispatch(startChecking())
                closeModal()
            },
            onError: (error) => console.log(error)
        })
    };

    const removeContact = () => {
        mutateContact({
            id: target.id,
            model: target.model,
            supplierId: supplier._id,
            contactId: contact._id
        }, {
            onSuccess: async () => {
                dispatch(startChecking())
                setConfirmation(false)
                closeModal()
            },
            onError: (error) => console.log(error)
        })
    };

    return (

        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    {
                        confirmation &&
                        <ModalConfirmation
                            action={removeContact}
                            closeConfirmation={() => setConfirmation(false)}
                            msg='Are you sure you want to remove this contact?'
                            buttonName='Remove Contact'
                            isLoading={isRemoving}
                        />
                    }

                    <div className='flex-space-between mb-2'>
                        <h4>Edit Contact</h4>
                        <button onClick={() => setConfirmation(true)}><IoTrashOutline /> </button>
                    </div>

                    <p className='font-normal mb-02 ml-02'>Name</p>
                    <input
                        type="text"
                        value={newName}
                        maxLength={30}
                        onChange={(e) => setNewName(e.target.value)}
                    />

                    <p className='mt-2 font-normal mb-02 ml-02'>Email</p>
                    <input
                        className="mb-1"
                        type="text"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                    />

                    <ModalBtn
                        action={editContact}
                        closeModal={closeModal}
                        actionTitle='Edit Contact'
                        isLoading={isLoading}
                    />

                </div>
            </div>
        </div>

    )
}
