import { capitalize } from "../helpers/eliminarEs";
import { Fruit } from "../interfaces/interfaces";
import { MainDataAllowed } from "./mainData";

export const batchData:MainDataAllowed = {
    pallet_ref: "",
    product: "",
    transport_document_1: "",
    transport_document_2: "",
    product_temperature: 0,
    format: "",
    supplier: "",
    grower: "",
    gln: "",
    ggn: "",
    coc: "",
    variety: "",
    commercial_variety: "",
    type_of_punnet: "",
    type_of_cover: "",
    type_of_colli: "",
    type_of_label: "",
    total_boxes: 0,
    total_pallets: 0,
    total_colli: "",
    total_kilos: 0,
    quality: "",
    transport: "",
    purchase_order: "",
    delivery_note: "",
    warehouse: "",
    type_gps_tracker: "",
    tracker_number: "",
    samples: 0,
    format_gr: 0

    // variety_ies: "",
}

export const basicBatchData:MainDataAllowed = {
    pallet_ref: "",
    supplier: "",
    product: "",
    format: "",
    grower: "",
    total_kilos: 0,
    total_boxes: 0,
    total_pallets: 0,
    variety: "",
    quality: "",
    transport: "",
    format_gr: 0,
    loading_date: null,
    arrival_date: null
}

export const basicBatchDataRepack = {
    pallet_ref: "",
    supplier: "",
    product: "",
    date: new Date(),
    format: "",
    staff_numbers: "1",
    repack_supervisor: "",
    warehouse: "",
    start_time: "",
    end_time: "",
}

export const batchDataProduct = ( fruit:Fruit ) => {
    const {pallet_ref, product, supplier, ...rest} = basicBatchData
    return {
        pallet_ref: "",
        supplier: "",
        product: capitalize(fruit),
        ...rest
    }
};

export const batchDataRepack = ( fruit:Fruit ) => {
    const {pallet_ref, supplier, product, ...rest} = basicBatchDataRepack
    return {
        pallet_ref: "",
        supplier: "",
        product: capitalize(fruit),
        ...rest
    }
};

export const batchDataFast = ( fruit:Fruit ) => {
    return {
        pallet_ref: "",
        supplier: "",
        product: capitalize(fruit),
    }
};

export const allMainData:MainDataAllowed = {
    pallet_ref: "",
    extra_ref: "",
    product: "",
    format: "",
    supplier: "",
    grower: "",
    total_kilos: 0,
    total_boxes: 0,
    total_pallets: 0,
    transport_document_1: "",
    transport_document_2: "",
    product_temperature: 0,
    origin: "",
    gln: "",
    ggn: "",
    coc: "",
    variety: "",
    unit_label: "",
    commercial_variety: "",
    type_of_punnet: "",
    type_of_cover: "",
    type_of_colli: "",
    type_of_label: "",
    total_colli: "",
    quality: "",
    transport: "",
    purchase_order: "",
    delivery_note: "",
    warehouse: "",
    type_gps_tracker: "",
    tracker_number: "",
    samples: 0,
    format_gr: 0,
    types_of_defects : "",
    discarded_fruit_kilos: 0,
    discarded_fruit_boxes: 0,
    loading_date: null,
    arrival_date: null
}

export const units = [
    "MM",
    "Inch",
    "Gram",
    "Kilo",
    "Ounce",
    "Lb",
]

