import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainData } from './Inputs/MainData'
import { useIntake } from '../../api/useIntake'
import { formatSplit, totalSamples } from '../../helpers/formatSplit'
import { fruitType } from '../../helpers/fruitType'
import { Charging } from '../ui/Charging'
import { SelectFruit } from '../ui/SelectFruit'
import { Fruit } from '../../interfaces/interfaces'
import ButtonSubmit from '../ui/ButtonSubmit'
import { useFetchInitialReport } from '../../api/useInitialReport'
import { InitialPallets, InitialReport, MainData as MainDataType } from '../../interfaces/intakes.reports'
import { palletData } from '../../data/pallet-steps'
import toast from 'react-hot-toast'
import TeamSelector from './Inputs/TeamSelector'
import { SupplierSelector } from './Inputs/SupplierSelector'
import { mainDataWithoutSupplier } from '../../helpers/mainDataConvert'
import { csvToJson } from '../../data/mainData'

export const Intake = () => {

    const navigate = useNavigate()

    const { id } = useParams()
    const { data, isLoading } = useIntake(id, "intake")
    const { mutate, isLoading: isIntaking } = useFetchInitialReport()

    const [mainData, setMainData] = useState<MainDataType | null>(null)
    const [fruit, setFruit] = useState<Fruit>("other")
    const [team, setTeam] = useState<string | null>(null)
    const [supplier, setSupplier] = useState<string | null>(null)


    useEffect(() => {
        if (data) {
            setTeam(data.team?._id ?? null)
            setFruit(fruitType(data.data.product) || "other")

            const { pallet_ref, supplier, ...rest } = csvToJson(data.data)

            setMainData({
                pallet_ref: pallet_ref || "",
                supplier: supplier || "",
                ...rest
            })

            // setMainData({
            //     pallet_ref: data.data.pallet_ref || "",
            //     supplier: data.data.supplier || "",
            //     ...data.data,
            //     // kilos: data.data.kilos || totalKilos(data.data.format, data.data.total_boxes).toString() || "",

            //     // total_pallets: data.data.total_pallets || "1",
            //     // samples: totalSamples(data.data.format || "0*0").toString() || "1",
            //     // format_gr: formatSplit(data.data.format).toString() || "",
            // })
        }
    }, [data])

    const saveInitialReport = () => {

        if (!mainData?.pallet_ref) return toast.error("Please enter a Pallet reference")

        let fetchPallets: InitialPallets[] = []

        if (mainData?.total_pallets >= 1 && mainData?.total_pallets <= 10) {
            for (let index = 0; index < Number(mainData?.total_pallets); index++) {
                fetchPallets.push(palletData(
                    fruit,
                    (+mainData.samples) || (totalSamples(mainData?.format) || 1),
                    (formatSplit(mainData?.format) || 1),
                    index + 1
                ))
            }
        }

        const fetchData: InitialReport = {
            mainData,
            fruit,
            intakeId: id,
            team,
            supplier: supplier,
            pallets: fetchPallets
        }

        mutate(fetchData, {
            onSuccess: async (data) => {
                navigate(`/create-report/${data.reportId}`)
            },
            onError: () => toast.error("Something went wrong, please try again later")
        })
    };

    return (
        <div className="content intake">
            <main className="container">
                {
                    isLoading || !mainData
                        ? <Charging />
                        :
                        <>
                            <h3 className='mb-1'>Report</h3>
                            <div className='mb-3'>
                                <TeamSelector setTeam={setTeam} team={team} />


                                <SupplierSelector
                                    team={team}
                                    mainData={mainData}
                                    setMainData={setMainData}
                                    supplier={supplier}
                                    setSupplier={setSupplier}
                                />

                                <MainData
                                    mainData={mainDataWithoutSupplier(mainData)}
                                    setMainData={setMainData}
                                    isList={false}
                                />

                                <SelectFruit
                                    fruit={fruit}
                                    setFruit={setFruit}
                                />
                            </div>

                            <ButtonSubmit
                                center
                                title='Start Report'
                                action={saveInitialReport}
                                color="green"
                                isLoading={isIntaking}
                            />
                        </>
                }
            </main>

        </div>
    )
}
