import { useState } from 'react'
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export const FilterWindow = ({ isSearchPage }) => {

    const navigate = useNavigate();

    const [filterPalletRef, setFilterPalletRef] = useState("");
    const [filterGrower, setFilterGrower] = useState("");
    const [filterStatus, setFilterStatus] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const refresh = (item) => {
        if (item === "date") { setStartDate(null); setEndDate(null) }
        if (item === "palletRef") setFilterPalletRef("")
        if (item === "grower") setFilterGrower("")
    };

    // const checkedBtn = () => {
    //     document.getElementById("search").checked = false
    // }

    const handleFilter = async () => {

        let newQuery = {}

        if (filterPalletRef.trim().length > 0) newQuery.palletRef = filterPalletRef
        if (filterGrower.trim().length > 0) newQuery.grower = filterGrower
        if (startDate && endDate) { newQuery.startDate = startDate; newQuery.endDate = endDate }
        if (filterStatus) newQuery.status = filterStatus

        if (Object.keys(newQuery).length === 0) return toast.error("You must select at least one filter")

        // checkedBtn()

        navigate({
            pathname: '/life-search',
            search: `?${new URLSearchParams(newQuery).toString()}`
        })
    };

    const selectStatus = (item) => {
        if (filterStatus !== null && filterStatus === item) return setFilterStatus(null)
        else setFilterStatus(item)
    };


    return (
        <div className="flex">

            {
            isSearchPage &&
                <button
                    onClick={() => navigate("/life")}
                    className="clear-filter mr-1">Clean Filter
                </button>
            }


            <p className="font-small mr-05">Filter</p>

            <nav className="searching">
                <div className="searching__container">
                    <div className="searching__menu">

                        <label htmlFor="search" className="filter">
                            <img src='./assets/img/filter-icon.svg' alt='filter-icon' />
                        </label>

                        <input type="checkbox" name="search" id="search" />
                        <div className="searching__list px-3" name="search">
                            <label htmlFor="search" className="close-icon">
                                <img src="/assets/img/close-icon-black.svg" alt="close-icon" />
                                <input type="checkbox" name="search" id="search" />
                            </label>
                            <h2 className="mb-2 ml-05">Filter by</h2>


                            {/* Filter by Date */}
                            <p className="mb-02 font-small bold ml-05">Date:</p>
                            <div className="filter-input mb-2">

                                <DatePicker
                                    className="date-input"
                                    dateFormat="dd / MM / yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    placeholderText="Select a date"
                                />
                                <DatePicker
                                    className="date-input date-filter"
                                    dateFormat="dd / MM / yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    placeholderText="Select a date"
                                />


                                <button className="ml-05"
                                    onClick={() => refresh('date')}
                                >
                                    <img src='assets/img/close-icon.svg' alt="close-supplier" />
                                </button>
                            </div>



                            {/* Filter by Pallet Ref */}
                            <p className="mb-02 font-small bold ml-05">Pallet Ref.:</p>
                            <div className="filter-input mb-2">
                                <input
                                    type='text'
                                    value={filterPalletRef}
                                    onChange={(e) => setFilterPalletRef(e.target.value)}
                                />
                                <button className="ml-05"
                                    onClick={() => refresh('palletRef')}
                                >
                                    <img src='assets/img/close-icon.svg' alt="close-supplier" />
                                </button>
                            </div>



                            {/* Filter by Grower */}
                            <p className="mb-02 font-small bold ml-05">Grower:</p>
                            <div className="filter-input mb-2">
                                <input
                                    type='text'
                                    value={filterGrower}
                                    onChange={(e) => setFilterGrower(e.target.value)}
                                />
                                <button className="ml-05"
                                    onClick={() => refresh('grower')}
                                >
                                    <img src='assets/img/close-icon.svg' alt="close-supplier" />
                                </button>
                            </div>



                            {/* Filter by Grower */}
                            <p className="mb-02 font-small bold ml-05">Status:</p>
                            <div className="filter-input status-grid mb-2">
                                <button
                                    onClick={() => selectStatus('pending')}
                                    className={`stat-pending ${filterStatus === "pending" ? 'stat-selected' : null}`}>PENDING</button>
                                <button
                                    onClick={() => selectStatus('in-process')}
                                    className={`stat-in-process ${filterStatus === "in-process" ? 'stat-selected' : null}`}>IN PROCESS</button>
                                <button
                                    onClick={() => selectStatus('done')}
                                    className={`stat-done ${filterStatus === "done" ? 'stat-selected' : null}`}>DONE</button>
                            </div>



                            {/* Search Button */}
                            <button
                                className="mt-2 btn-primary outline shadow"
                                onClick={handleFilter}
                            >Search
                            </button>



                        </div>
                    </div>
                </div>
            </nav>

        </div>
    )
}
