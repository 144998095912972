import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


export const datesInputs = ["date","loading_date", "arrival_date"]
export const numbersInputs = ["product_temperature", "staff_numbers", "total_pallets", "total_boxes", "total_kilos", "kilos", "format_gr", "samples", "discarded_fruit_kilos", "discarded_fruit_boxes"]
export const timeInputs = ["start_time", "end_time"]

const validMainData = [
    "pallet_ref",
    "extra_ref",
    "product",
    "format",
    "supplier",
    "grower",
    "total_kilos",
    "total_boxes",
    "total_pallets",
    "transport_document_1",
    "transport_document_2",
    "product_temperature",
    "origin",
    "gln",
    "ggn",
    "coc",
    "variety",
    "unit_label",
    "commercial_variety",
    "type_of_punnet",
    "type_of_cover",
    "type_of_colli",
    "type_of_label",
    "total_colli",
    "quality",
    "transport",
    "purchase_order",
    "delivery_note",
    "warehouse",
    "type_gps_tracker",
    "tracker_number",
    "samples",
    "format_gr",
    "types_of_defects",
    "discarded_fruit_kilos",
    "discarded_fruit_boxes",
    "loading_date",
    "arrival_date",
]

export interface MainDataAllowed {
    pallet_ref: string,
    extra_ref?: string,
    supplier: string,
    product?: string,
    format?: string,
    grower?: string,
    total_kilos?: number,
    total_boxes?: number,
    total_pallets?: number,
    transport_document_1?: string,
    transport_document_2?: string,
    product_temperature?: number,
    origin?: string,
    gln?: string,
    ggn?: string,
    coc?: string,
    gln_ggn_number?: string,
    variety?: string,
    unit_label?: string,
    commercial_variety?: string,
    type_of_punnet?: string,
    type_of_cover?: string,
    type_of_colli?: string,
    type_of_label?: string,
    total_colli?: string,
    quality?: string,
    transport?: string,
    purchase_order?: string,
    delivery_note?: string,
    warehouse?: string,
    type_gps_tracker?: string,
    tracker_number?: string,
    samples?: number,
    format_gr?: number,
    types_of_defects?: string,
    discarded_fruit_kilos?: number,
    discarded_fruit_boxes?: number,
    loading_date?: string | null,
    arrival_date?: string | null,
}

enum ReplaceKey {
    delivery_note_awb_number = "delivery_note",
    pallet = "pallet_ref",
    pallet_reference = "pallet_ref",
    kilos = "total_kilos",
}


export const csvToJson = (csv: Record<string, string | number>): Record<string, string | number | Date | null> => {

    const newJson: Record<string, string | number | Date | null> = {};

    for (const key in csv) {
        if (!key.trim()) continue
        const newKey: string = headerToJSON(key.trim()) in ReplaceKey ? ReplaceKey[headerToJSON(key.trim()) as keyof typeof ReplaceKey] : headerToJSON(key.trim())
        if (!validMainData.includes(newKey)) continue

        if (csv.hasOwnProperty(key)) {
            newJson[newKey] = datesInputs.includes( newKey ) ? finalDate(csv[key]) : ( numbersInputs.includes(newKey) ? ((+csv[key]).toString() || "") : csv[key] || "" )
        }
    }
    return newJson;
};


//Convert header CSV name to string. e.g: "Tracker / ID" => "tracker_id"
export const headerToJSON = (str: string): string => str.trim().split(/[^A-Za-z0-9]+/).join('_').toLowerCase();

const finalDate = ( date : Date | string | number): Date | string | null => {

    dayjs.extend(customParseFormat);

    if (date instanceof Date) {
        return isNaN(date.getTime()) ? null : date;
    }

    if (typeof date === 'number') {
        return null
    }

    if (typeof date === 'string') {
        const formats = ["DD-MM-YYYY", "DD-MM-YY", "DD/MM/YYYY", "DD/MM/YY"];
        for (const format of formats) {
            const parsedDate = dayjs(date, format, true);
            if (parsedDate.isValid()) {
                return parsedDate.toDate();
            }
        }
    }

    return null; // Si no es un Date válido o un string en el formato correcto
};

// {
//     "pallet_ref": "PLFIEL 0724 24*125",
//     "product": "Raspberries",
//     "format": "24*125g",
//     "supplier": "Fieldstone Investments II SP. Z.O.O.",
//     "grower": "Fieldstone Investments II Sp. z o.o.",
//     "origin": "POLAND",
//     "gln_ggn_number": "4056186807151",
//     "variety_ies_": "",
//     "unit_label": "Without lid",
//     "total_boxes": "130",
//     "total_pallets": "1",
//     "quality": "Class 1",
//     "transport": "",
//     "purchase_order": "PO:24-01125",
//     "delivery_note_awb_number": "",
//     "warehouse": "EFF-Leursebaan",
//     "kilos": "390",
//     "samples": "24",
//     "format_gr": "125"
//   }

